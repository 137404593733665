import React from "react"
import { graphql } from 'gatsby';

import SEO from '../components/PageSEO';
import Layout from '../containers/layout/layout'
import Banner from '../containers/projects/banner'
import Recent from '../components/Recent'

const ProjectPage = ({ data: { blog } }) => {
  return (
    <Layout>
      <SEO title="Furkan Acar | Mimari Projeler | Konya"
        description="Mimari Proje işleri, Mimar, Konya Mimarlık Ofisi, Konya Mimar, Konya En İyi Mimar, Konya Tavsiye Edilen Mimar, Konya İç Mimarlık, Mimari Proje, Dış Mimar Çizimleri" />
      <Banner />
      <Recent posts={blog.edges} />
    </Layout>
  );
};
export default ProjectPage;

export const homeQuery = graphql`
{
    blog: allDatoCmsProject(limit: 1000) {
        edges {
          node {
            id
            title
            sector
            client
            featuredimage {
                fluid(maxWidth: 1760, maxHeight: 990) {
                    ...GatsbyDatoCmsFluid_noBase64
                  }
            }
          }
        }
      }
    }
    `